<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-add
        v-if="getAccess('task.edit', { users: [data.user_id], data })"
        @click="showRedirectDialog = true"
        :disabled="loading"
        icon="fas fa-people-arrows"
        title=""
      />
      <a-btn-status v-if="getAccess('task.status', { users: [data.user_id] })" :items="statusTaskItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit v-if="getAccess('task.edit', { users: [data.user_id], data })" @click="showEditDialog = true" :disabled="loading" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id" :api="url" @close="itemShowClose($event)"></edit-dialog>
    </portal>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Задача [{{ statusCurrent.text }}]</div>
          </template>
          <a-form-view
            :value="data"
            :model="[
              {
                name: 'document_route',
                title: 'Документ',
                type: 'selectapi',
                itemField: { value: 'id', text: 'code_doc' },
                api: 'accounting/doc/order_good',
                routeName: 'ordersGoods_view',
                preText: 'Заказ товаров №',
                value: constructionGoodsTable ? constructionGoodsTable.doc_id || 0 : 0,
                isMenu: true,
              },
              ...getFormModel(['name', 'is_important', 'description']),
            ]"
            :config="{ dense: true, hideNull: true }"
            :style="{ height: blockHeight + 'px' }"
            @click="onClick($event)"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Состояние</div>
          </template>
          <a-form-view :value="data" :model="getModel(2)" :config="{ dense: true }" class="block_master" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" class="px-2">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(t, i) in tabs" v-if="t.show" :key="i" :href="`#tab-${t.name}`" class="mr-3">{{ t.title }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'goods'" :style="`height: ${detailHeight}px; overflow: auto`">
                <ViewGoods v-if="constructionGoodsData" :id="data.params.parent_id || 0" :dataTable="constructionGoodsData" ref="goods" />
              </v-card-text>
              <v-card-text v-if="t.name == 'files'" :style="`height: ${detailHeight}px; overflow: auto`">
                <v-fab-transition>
                  <v-btn
                    v-if="getAccess('task.edit', { users: [data.user_id], data })"
                    :disabled="loading"
                    fab
                    dark
                    color="green"
                    absolute
                    bottom
                    x-small
                    style="bottom: 15px"
                    left
                    class="v-btn--example"
                    @click="showAddFilesDialog = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-files :value="data.files" />
                <a-view-filesDialog v-if="showAddFilesDialog" v-model="showAddFilesDialog" :files="data.files" @save="addNewFiles($event)" />
              </v-card-text>
              <v-card-text v-if="t.name == 'comments'" :style="`height: ${detailHeight}px; overflow: auto`">
                <ViewComments :id="id || 0" :needUpdate="needUpdateComments" :target="{ name: 'buying' }" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <RedirectTask v-if="showRedirectDialog" v-model="showRedirectDialog" @redirect="onRedirect($event)" />

    <StateBuyGoodsDialog2
      v-if="showBuyGoodsDialog2"
      v-model="showBuyGoodsDialog2"
      :id="id"
      :object="JSON.parse(JSON.stringify(data))"
      @refresh="
        getConstructionGoodsTable();
        fitchData();
      "
    />
    <comment-edit
      :value="showAddCommentDialog"
      @input="
        showAddCommentDialog = false;
        changeStatus(newStatus, $event);
      "
      :id="0"
      :target="{ id, name: 'buying' }"
    />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";

import { autoHeightBlock, getAccess, popupMenu, genModel } from "../../components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, genModel],
  components: {
    editDialog: () => import("./../dialogs/taskBuyingDialog"),
    ViewGoods: () => import("./tasksViewGoods"),
    ViewComments: () => import("./tasksViewComments"),
    StateBuyGoodsDialog2: () => import("./../dialogs/stateBuyGoodsDialog2"),
    RedirectTask: () => import("./../dialogs/taskRedirectDialog"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      needUpdateComments: 0,
      showEditDialog: false,
      showRedirectDialog: false,
      showBuyGoodsDialog2: false,
      showAddCommentDialog: false,
      showAddFilesDialog: false, 
      loading: false,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.buying.form,
      statusTask: this.$store.getters["config/get"].models.buying.statuses,
      url: "mechti/buying",
      tab: 0,
      tabs: [
        { name: "goods", title: "Чек-лиск", show: true },
        { name: "files", title: "Файлы", show: true },
        { name: "comments", title: "Комментарии", show: this.getAccess(`task.comments`, null, true) },
      ],
      blockHeight: 256,
      newStatus: null,
      financeTable: null,
      constructionGoodsTable: null,
    };
  },
  created() {
    this.$root.title = "Покупка по чек-листу";
    this.id = this.idShow || Number(this.$route.params.id);
    if (this.idShow) {
      // this.$router.push({ path: this.$route.path + `/${this.idShow}` });
    }
    this.fitchData();
  },
  mounted() {},

  computed: {
    constructionGoodsData() {
      if (!this.constructionGoodsTable) this.getConstructionGoodsTable();
      return this.constructionGoodsTable;
    },

    filters() {
      return {
        object_id: this.data.object_id,
        params: {
          condition: "LIKE",
          value: '%"parent_id":' + (this.data.params?.parent_id || 0) + "%",
        },
      };
    },
    statuses() {
      return this.statusTask;
    },
    statusTaskItems() {
      let items = [];
      let res = [];
      if (this.statusCurrent?.["next"]) {
        let arr = this.statusCurrent["next"];
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data.status;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    "data.status"() {
      this.needUpdateComments++;
    },
  },
  methods: {
    async onRedirect(data) {
      data["id"] = this.id;
      this.loading = true;
      let response = await this.$axios.post("/mechti/buying/redirect", data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Задача переназначена",
        });
        this.fitchData();
        this.needUpdateComments++;
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при переназначении",
        });
      }
    },
    itemShowClose() {
      if (this.idShow) this.$emit("close");
      else this.$router.push({ name: this.$route.path.split("/")[1] });
    },
    async getConstructionGoodsTable() {
      let res;
      if (!this.data?.params?.parent_id) return;
      let d = await this.$axios.get("mechti/construction-goods/" + (this.data?.params?.parent_id || 0));
      if (d.data && d.data.data) {
        res = d.data.data;
      }
      this.constructionGoodsTable = res;
    },

    async fitchData() {
      this.loading = true;
      try {
        const d = await this.$axios.get(this.url + "/" + this.id);
        if (d.data && d.data.data) {
          this.data = d.data.data;
        }
      } catch (error) {
        if (this.idShow) this.$emit("close");
        else this.$router.push({ name: this.$route.path.split("/")[1] });
        throw error;
      }

      this.loading = !true;
      this.$store.dispatch("state/badges", "task_items");
    },
    getFormModel(arr) {
      let res = [];
      arr.forEach((el) => {
        res = [
          ...res,
          this.model.filter((m) => {
            return m.name == el;
          })[0],
        ];
      });
      return res;
    },
    getModel(type) {
      let model = [];
      if (type == 2) {
        model = this.getFormModel(["createdon", "date_start", "date_end", "creator_name", "user_name"]);
        if (this.data?.object_name) {
          model = [
            {
              name: "object_name",
              title: "Объект",
              type: "string",
              isMenu: true,
            },
            ...model,
          ];
        }
      }
      return model;
    },
    onClick(e) {
      console.log("onClick", e);
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event, e);
      }
    },
    async checkRules(rules, category) {
      let can_buy = !true;
      if (!rules) return true;

      rules = JSON.parse(rules)?.buying_rules || null;
      if (!rules) return true;
      let sql = "";

      rules
        .filter((r) => r.type == 1)
        .forEach((rule) => {
          let str = rule.services.join(",");
          sql += sql ? " UNION all " : "";
          sql += `SELECT case when COUNT(1)>0 then 1 ELSE 0 END  AS res FROM accounting_doc_act_work WHERE object_id=${this.data.object_id}  AND  service_id in (${str})`;
        });
      if (!sql) return true;
      sql = `SELECT SUM(res) as res FROM (${sql}) AS t`;
      let resp = await this.$axios.post("accounting/records/report-sql", { q: sql });
      if (resp.data.status == "ok") {
        can_buy = resp.data.data[0].res == rules.length ? true : false;
      }
      return can_buy;
    },
    async clickStatus(status) {
      this.newStatus = null;
      if (!this.data.user_id) {
        this.$root.$emit("show-info", {
          text: "У задачи нет исполнителя.",
          type: "error",
        });

        return;
      }
      let ok = false;

      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите поменять статус на [${status.text}]?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.func) {
        this[status.func](status);
      } else {
        this.changeStatus(status);
      }
      this.fitchData();
    },

    async statusBuyGoods2(status) {
      console.log(status);
      if (status.value == 220) {
        if (!this.constructionGoodsData) return;
        let can_buy = await this.checkRules(this.constructionGoodsData.category_data, this.constructionGoodsData.category_id);
        //console.log("ddddd", can_buy, this.constructionGoodsData.category_data, this.constructionGoodsData.category_id);
        if (!can_buy) {
          await this.$refs.confirmDialog.show({
            title: "Планирование закупа",
            message: [`Товар не может быть закуплен.`, "На объекте не выполнены обязательные работы"],
            okButton: "Понятно",
            cancelButton: "Закрыть",
          });
          return;
        }
      }
      this.newStatus = status;
      this.showBuyGoodsDialog2 = true;
    },
    statusAddComment(status) {
      this.newStatus = status;
      this.showAddCommentDialog = true;
    },

    async changeStatus(status, e) {
      this.loading = true;
      let data = { id: this.data.id, status: status.value };
      if (status.value == 298) data.redirectComment = e.content;
      let response = await this.$axios.post(this.url, data);
      this.loading = false;
      this.fitchData();
      let res = response.data.status == "ok";
      if (res) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
    async addNewFiles(files) {
      this.loading = true;
      let data = { id: this.data.id, files };
      let response = await this.$axios.post(this.url, data);
      this.loading = false;

      const status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Файлы добавлены",
        });
      }
    },
  },
};
</script>
